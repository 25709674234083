import React, {Component} from "react";

interface Props{
    clientCompanyName:string | undefined
}

interface State {
}

class DirectDebitAgreement extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <div>
            <div className={"row"}>
                <div className={"col"}>
                    <h4>Direct Debit Agreement</h4>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    {/*<p>*/}
                    {/*    Where your Net Daily Processing or your Latpay account is negative, or Latpay have requested you to fund your account for any reason associated with the Latpay Acquiring and Payment Services Agreement, you authorise Latpay to debit your registered Bank Account by using the Bulk Electronic Clearing Systems (BECS).*/}
                    {/*</p>*/}
                    <p>
                        On behalf of
                        <b> {this.props.clientCompanyName} </b>
                        ,I/We authorise <b>Vending on Track Pty Ltd</b> trading as Vending on Track(VOT) under User ID 613606 to debit my bank account.
                        Transfers to and from your Bank Account will come from VOT and the timing of the transfers will be according to your Payment Schedule.
                    </p>
                    <p>
                        It is your responsibility to:
                    </p>
                    <ol>
                        <li>
                            Check and confirm that your Bank Account can accept debit and credit transfers through BECS.
                        </li>
                        <li>
                            Check your bank statement account details and to verify that all amounts are correct.
                        </li>
                        <li>
                            Ensure that your account has sufficient funds to allow for a debit transfer.
                        </li>
                        <li>
                            If transfers are returned to VOT by your bank, we may charge you a fee of $10.00 per BECS decline in addition to any fees your bank may charge. If a debit transfer is returned to VOT because your Bank Account has insufficient funds, it is your responsibility to fund the Bank Account so that we can re-process the debit transfer.
                        </li>
                    </ol>
                    <p>
                        If you believe there has been an error in debiting your account, you should notify us immediately neel support@vendingontrack.com. We also recommend you notify your bank. If there has been an error, we’ll arrange with your bank to adjust your account and we’ll notify you of the amount of the adjustment. If we don’t find an error, we’ll respond to your query with evidence of the correctness of the transfer.
                    </p>
                    <p>
                        VOT will provide you with at least 14 days’ notice before any changes to this Direct Debit Request Service Agreement. If you’d like to amend this Direct Debit Request Service Agreement or to request that a transfer be deferred or altered, you can do so by emailing us, and we will make the change within 5 business days. You can also request that a transfer be stopped, cancelled, or deferred through your bank.
                    </p>
                    <p>
                        Information relating to this Direct Debit Request Service Agreement is treated according to VOT’s Privacy Policy
                    </p>
                </div>
            </div>
        </div>
    }
}

export {DirectDebitAgreement}

