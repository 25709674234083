import React, {Component} from "react";

interface Props{
    abn:string

    isAgreedServiceAgreement:boolean
    isAgreedFeeStructure:boolean
    isAgreedDirectDebitAgreement:boolean

    bsb:string
    accountNumber:string
    accountName:string

    addLine1:string
    addLine2:string
    city:string
    state:string
    country:string
    postcode:string

    clientFirstName:string
    clientLastName:string
    clientPhone:string
    clientEmail:string

    handleStepChange(step:number):void
    handleSubmit(e:any):void
}

interface State {
}

class SummaryPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <div className={"container mt-5"}>
            <div className={"row"}>
                <div className={"col"}>
                    <h3>Summary</h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">ABN</label>
                            <input disabled className="form-control" value={this.props.abn}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">BSB (number only, without space or '-')</label>
                            <input disabled className="form-control" value={this.props.bsb}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Account Number (number only, without space or '-')</label>
                            <input disabled className="form-control" value={this.props.accountNumber}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input disabled className="form-control" value={this.props.accountName}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Address line 1</label>
                            <input disabled className="form-control" value={this.props.addLine1}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Address line 2</label>
                            <input disabled className="form-control" value={this.props.addLine2}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Suburb</label>
                            <input disabled className="form-control" value={this.props.city}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">State</label>
                            <input disabled className="form-control" value={this.props.state}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Country</label>
                            <input disabled className="form-control" value={this.props.country}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Postcode</label>
                            <input disabled className="form-control" value={this.props.postcode}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">First Name</label>
                            <input disabled className="form-control" value={this.props.clientFirstName}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            <input disabled className="form-control" value={this.props.clientLastName}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Phone</label>
                            <input disabled className="form-control" value={this.props.clientPhone}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input disabled className="form-control" value={this.props.clientEmail}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>
                                Agree VoT Service Agreement
                            </th>
                            <th>
                                Agree VoT Fee Structure
                            </th>
                            <th>
                                Agree VoT Direct Debit Agreement
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                {this.props.isAgreedServiceAgreement ? "Yes" : "No"}
                            </td>
                            <td>
                                {this.props.isAgreedFeeStructure ? "Yes" : "No"}
                            </td>
                            <td>
                                {this.props.isAgreedDirectDebitAgreement ? "Yes" : "No"}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col d-flex justify-content-between"}>
                    <button type="button" className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleStepChange(3);
                    }}>
                        Previous
                    </button>
                    <button type="button" className="btn btn-primary" onClick={this.props.handleSubmit.bind(this)}>
                        Submit
                    </button>
                </div>
            </div>
        </div>;
    }
}

export {SummaryPage}
