import {Component} from "react";

interface Props{
    isChecked:boolean
    fileName:string
    buttonClick(e:any):void
}

interface State {
}

class IAgreeCheckbox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <label>
            <input type="checkbox" checked={this.props.isChecked} onChange={this.props.buttonClick.bind(this)}/>
            &nbsp;
            <span>You have read and agree to the terms of the <b>VOT {this.props.fileName}</b></span>
        </label>;

    }
}

export {IAgreeCheckbox}
