import React, {Component} from "react";

interface Props{
    cents?: number
    dollar?: number
    smallSign?: boolean
}

interface State {

}

class _FormatMoney extends Component<Props, State> {
    constructor(props:Props) {
        super(props);

        this.state = {}
    }

    render() {
        let amount = (this.props.cents || 0) / Math.pow(10, 2);
        if (!amount) {
            amount = this.props.dollar || 0;
            if (!amount) {
                amount = 0.0;
            }
        }

        const sign = this.props.smallSign ? <small>$</small>:"$";
        return (
            <>
                {sign}{amount.toFixed(2)}
            </>
        );
    }
}

export const FormatMoney = _FormatMoney;
