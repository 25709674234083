import React, {Component} from "react";
import {IAgreeCheckbox} from "./i_agree_checkbox";
import {FeeStructure} from "./client_onboarding_agreement";
import {FormatMoney} from "./format_money";


interface Props{
    isAgreed:boolean
    toggleIsAgree(e:any):void
    handleStepChange(step:number):void
    feeStructure:FeeStructure | undefined
}

interface State {
}

class FeeStructurePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <div className={"container mt-5"}>
            <div className={"row"}>
                <div className={"col"}>
                    <h3>Fee Structure</h3>

                </div>
            </div>
            <p>PLUS GST</p>
            <div className={"row"}>
                <div className={"col"}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Fee Category</th>
                            <th>Amount (+GST)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Device Service Fee
                            </td>
                            <td>
                                <FormatMoney cents={this.props.feeStructure?.device_service_fee}/> / Month - charged by VoT using direct debit, invoiced monthly.
                            </td>
                        </tr>
			<tr>
				<td>
					Direct Debit Surcharge
				</td>
				<td>
					<FormatMoney cents={88} /> / Month
				</td>
			</tr>
                        {!!this.props.feeStructure?.credit_card_transaction_fee &&
                        (<tr>
                            <td>
                                Credit Card Transaction Fee
                            </td>
                            <td>
                                <FormatMoney cents={this.props.feeStructure?.credit_card_transaction_fee}/> /
                                Transaction (this rate is indicative, for details please refer to merchant agreement with the payment gateway)
                            </td>
                        </tr>)
                        }

                        {!!this.props.feeStructure?.vendcoin_prepaid_transaction_fee &&
                        <tr>
                            <td>
                                VendCoin Prepaid Transaction Fee
                            </td>
                            <td>
                                <FormatMoney cents={this.props.feeStructure?.vendcoin_prepaid_transaction_fee}/> /
                                Transaction
                            </td>
                        </tr>
                        }
                        {!!this.props.feeStructure?.vendcoin_payg_transaction_fee &&
                        <tr>
                            <td>
                                VendCoin PAYG Transaction Fee
                            </td>
                            <td>
                                <FormatMoney cents={this.props.feeStructure?.vendcoin_payg_transaction_fee}/> /
                                Transaction
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <IAgreeCheckbox isChecked={this.props.isAgreed} fileName={"Fee Structure"} buttonClick={this.props.toggleIsAgree.bind(this)}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col d-flex justify-content-between"}>
                    <button type="button" className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleStepChange(1);
                    }}>
                        Previous
                    </button>
                    <button disabled={!this.props.isAgreed} type="button" className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleStepChange(3);
                    }}>
                        Next
                    </button>
                </div>
            </div>
        </div>;
    }
}

export {FeeStructurePage}
