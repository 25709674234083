import React, {Component} from "react";

interface Props{
}

interface State {
}


class SuccessPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <div className="container">
            <div className={"alert alert-success"}>
                <b>Success</b>. Thank you.
            </div>
        </div>;
    }
}

export {SuccessPage}
