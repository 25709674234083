import React, {Component} from "react";
import logo from './img/logo.png'

interface Props{
}

interface State {
}


class StartPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <div className="container start">
            <h1>Billing Portal</h1>

            <p>
                Vending on Track
                <br />
            
                <a href="https://www.vendingontrack.com">
                    <img alt="logo" className="logo" src={logo} />
                </a>
            </p>

            

            <div className="alert alert-warning">
                <b>Billing Portal</b> is for Vending on Track clients.
            </div>

            <hr />

            <div>
                You will be directed to <a href="https://billing.vendingontrack.com">VoT Billing Portal</a> for the following events,
                <ul>
                    <li>onboarding</li>
                    <li>offboarding</li>
                    <li>transfer ownership</li>
                    <li>device disposal</li>
                </ul>
            </div>

            <div>
                You are not supposed to visit this website directly. 
            </div>

            <div className="alert alert-danger">
                <b>WARNING:</b> you should only follow instructions from a Vending on Track employee or authorized distributor. 
            </div>

            <hr />

            <h2>Contact Vending on Track customer support</h2>
            <ul>
                <li>Customer Support: +61 2 9160 6222</li>
                <li>Email: support@vendingontrack.com</li>
            </ul>

        </div>
    }
}

export {StartPage}
