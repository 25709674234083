import React, {Component} from "react";
import {getAPI, postAPI} from "./api";
import {ServiceAgreement} from "./service_agreement";
import {FeeStructurePage} from "./fee_structure";
import {BankInfoPage} from "./bank_info";
import {SummaryPage} from "./summary_page";
import {Redirect} from "react-router-dom";
import logo from './img/VOT_logo.png';


interface Props{
    location:any
    match:any
    history:any
}

interface State {
    agreement:Agreement | null
    isPageExist:boolean|null
    isClientAgreedServiceAgreement:boolean
    isClientAgreedFeeStructure:boolean
    isClientAgreedDirectDebit:boolean
    clientBsb:string
    clientAccountNumber:string
    clientAccountName:string
    clientAddressLine1:string
    clientAddressLine2:string
    clientCity:string
    clientState:string
    clientCountry:string
    clientPostCode:string
    clientFirstName:string
    clientLastName:string
    clientPhone:string
    clientEmail:string
    directDebitAuthorisedBy:string
    step:number
    isSubmitted: boolean
    abn:string
}

interface Client{
    company_name: string
}

export interface FeeStructure{
    device_service_fee:number
    credit_card_transaction_fee:number
    vendcoin_prepaid_transaction_fee:number
    vendcoin_payg_transaction_fee:number
}

interface Agreement{
    client:Client,
    fee_structure:FeeStructure
}

class _ClientOnboardingAgreement extends Component<Props, State> {
    qs = require('qs');
    constructor(props: Props) {
        super(props);

        this.state={
            agreement:null,
            isPageExist:null,
            isClientAgreedServiceAgreement:false,
            isClientAgreedFeeStructure:false,
            isClientAgreedDirectDebit:false,
            clientAccountName:"",
            clientAccountNumber:"",
            clientBsb:"",
            clientAddressLine1:"",
            clientAddressLine2:"",
            clientCity:"",
            clientState:"",
            clientCountry:"AU",
            clientPostCode:"",
            clientFirstName:"",
            clientLastName:"",
            clientPhone:"",
            clientEmail:"",
            step:1,
            directDebitAuthorisedBy:"",
            isSubmitted: false,
            abn:""
        }
    }

    componentDidMount() {
        this.loadingClientOnboardingAgreement();
    }

    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {
        if (prevProps.location.search.slice(1) !== this.props.location.search.slice(1)) {
            this.getStepFromUrl();
        }
    }

    loadingClientOnboardingAgreement(){
        getAPI(`/api/client_onboarding_agreement/${this.props.match.params.token}`).then((response)=>{
            if (response.data.success){
                this.setState({
                    isPageExist:true,
                    agreement:response.data.agreement
                })
            } else {
                this.setState({
                    isPageExist:false
                })
            }
        })
    }

    toggleIAgreeServiceAgreementCheckbox(e:any){
        e.stopPropagation();
        this.setState({isClientAgreedServiceAgreement: !this.state.isClientAgreedServiceAgreement})
    }

    toggleIAgreeFeeStructureCheckbox(e:any){
        e.stopPropagation();
        this.setState({isClientAgreedFeeStructure: !this.state.isClientAgreedFeeStructure})
    }

    toggleIAgreeDirectDebitCheckbox(e:any){
        e.stopPropagation();
        this.setState({isClientAgreedDirectDebit: !this.state.isClientAgreedDirectDebit})
    }

    handleBsbChange(e:any){
        this.setState({clientBsb:e.target.value})
    }

    handleAccountNumberChange(e:any){
        this.setState({clientAccountNumber:e.target.value})
    }

    handleAccountNameChange(e:any){
        this.setState({clientAccountName:e.target.value})
    }

    handleClientAddressLine1Change(e:any){
        this.setState({clientAddressLine1:e.target.value})
    }

    handleClientAddressLine2Change(e:any){
        this.setState({clientAddressLine2:e.target.value})
    }

    handleClientCityChange(e:any){
        this.setState({clientCity:e.target.value})
    }

    handleClientStateChange(e:any){
        this.setState({clientState:e.target.value})
    }

    handleClientCountryChange(e:any){
        this.setState({clientCountry:e.target.value})
    }

    handleClientPostcodeChange(e:any){
        this.setState({clientPostCode:e.target.value})
    }
    handleDirectDebitAuthorisedByChange(e:any){
        this.setState({directDebitAuthorisedBy:e.target.value})
    }

    handleFirstNameChange(e:any){
        this.setState({clientFirstName:e.target.value})
    }

    handleLastNameChange(e:any){
        this.setState({clientLastName:e.target.value})
    }

    handlePhoneChange(phoneNumber:string){
        this.setState({clientPhone:phoneNumber})
    }

    handleEmailChange(e:any){
        this.setState({clientEmail:e.target.value})
    }

    handleStepChange(step:number){
        this.setState({step:step})
        this.handleParamsChange("step", step.toString())
    }

    handleAbnChange(e:any){
        this.setState({abn:e.target.value})
    }

    handleSubmit(e:any){
        e.preventDefault();
        e.stopPropagation();

        postAPI(`/api/client_onboarding_agreement/${this.props.match.params.token}`, {
            abn:this.state.abn,
            is_agreed_service_agreement: this.state.isClientAgreedServiceAgreement,
            is_agreed_fee_structure: this.state.isClientAgreedFeeStructure,
            is_agreed_direct_debit: this.state.isClientAgreedDirectDebit,
            direct_debit_authorised_by: this.state.directDebitAuthorisedBy,
            bsb:this.state.clientBsb,
            account_name: this.state.clientAccountName,
            account_number: this.state.clientAccountNumber,
            address_line_1: this.state.clientAddressLine1,
            address_line_2: this.state.clientAddressLine2,
            city: this.state.clientCity,
            state: this.state.clientState,
            country: this.state.clientCountry,
            postcode: this.state.clientPostCode,
            first_name: this.state.clientFirstName,
            last_name: this.state.clientLastName,
            phone: this.state.clientPhone,
            email: this.state.clientEmail,
            service_agreement_version:process.env.REACT_APP_SERVICE_AGREEMENT_VERSION,
            direct_debit_agreement_version:process.env.REACT_APP_DIRECT_DEBIT_AGREEMENT_VERSION,
        }).then((response)=>{
            if (response.data.success){
                this.setState({isSubmitted:true})
            } else {
                window.alert(response.data.message)
            }
        })
    }

    getStepFromUrl(){
        const params = this.qs.parse(this.props.location.search.slice(1));
        this.setState({
            step:params['step'] !== "" ? params['step'] !== undefined ? parseInt(params['step']) : 1 : 1
        })
    }

    handleParamsChange(currentParam: string, newValue:string){
        let params = this.qs.parse(this.props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        this.props.history.push(paramsStr);
    }

    render() {
        if (this.state.isPageExist === null){
            return <div className="container">
                <div className="alert alert-warning">
                    <b>Loading...</b>
                </div>
            </div>
        }
        if (!this.state.isPageExist){
            return <div className="container">
                <div className="alert alert-warning">
                    <b>Invalid URL</b>. Please contact Vending on Track support.
                </div>
            </div>
        }

        if (this.state.isSubmitted){
            return <Redirect to="/success"/>
        }

        return <div className={"container my-5"}>
            <div className={"row"}>
                <div className={"col-lg-3 col-md-4"}>
                    <img src={logo} alt="VOT Logo" width={200}/>
                </div>
                <div className={"col-lg-9 col-md-8 py-5"}>
                    <h1 className={"py-4"}>Vending On Track Client Onboarding Form</h1>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <p>Client: <span style={{textDecoration: "underline"}}>{this.state.agreement?.client.company_name}</span></p>
                </div>

                <b>Steps:</b>
                <ol>
                    <li>Service Agreement {this.state.step === 1 && "(current)"}</li>
                    <li>Fees {this.state.step === 2 && "(current)"}</li>
                    <li>Direct Debit Authorisation {this.state.step === 3 && "(current)"}</li>
                </ol>
            </div>

            {this.state.step === 1 && <div className={"row"}>
                <div className={"col"}>
                    <ServiceAgreement isAgreed={this.state.isClientAgreedServiceAgreement}
                                      toggleIsAgree={this.toggleIAgreeServiceAgreementCheckbox.bind(this)}
                                      handleStepChange={this.handleStepChange.bind(this)}/>
                </div>
            </div>}
            {this.state.step === 2 && <div className={"row"}>
                <div className={"col"}>
                    <FeeStructurePage isAgreed={this.state.isClientAgreedFeeStructure}
                                      toggleIsAgree={this.toggleIAgreeFeeStructureCheckbox.bind(this)}
                                      handleStepChange={this.handleStepChange.bind(this)}
                                      feeStructure={this.state.agreement?.fee_structure}/>
                </div>
            </div>}
            {this.state.step === 3 && <div className={"row"}>
                <div className={"col"}>
                    <BankInfoPage handleStepChange={this.handleStepChange.bind(this)}
                                  handleAccountNameChange={this.handleAccountNameChange.bind(this)}
                                  handleAccountNumberChange={this.handleAccountNumberChange.bind(this)}
                                  handleBsbChange={this.handleBsbChange.bind(this)}
                                  handleClientAddressLine1Change={this.handleClientAddressLine1Change.bind(this)}
                                  handleClientAddressLine2Change={this.handleClientAddressLine2Change.bind(this)}
                                  handleClientCityChange={this.handleClientCityChange.bind(this)}
                                  handleClientCountryChange={this.handleClientCountryChange.bind(this)}
                                  handleClientStateChange={this.handleClientStateChange.bind(this)}
                                  handleClientPostcodeChange={this.handleClientPostcodeChange.bind(this)}
                                  handleFirstNameChange={this.handleFirstNameChange.bind(this)}
                                  handleLastNameChange={this.handleLastNameChange.bind(this)}
                                  handlePhoneChange={this.handlePhoneChange.bind(this)}
                                  handleEmailChange={this.handleEmailChange.bind(this)}
                                  clientState={this.state.clientState}
                                  clientAccountNumber={this.state.clientAccountNumber}
                                  clientAccountName={this.state.clientAccountName}
                                  clientBsb={this.state.clientBsb}
                                  clientCity={this.state.clientCity}
                                  clientAddressLine1={this.state.clientAddressLine1}
                                  clientAddressLine2={this.state.clientAddressLine2}
                                  clientCountry={this.state.clientCountry}
                                  clientPostCode={this.state.clientPostCode}
                                  clientFirstName={this.state.clientFirstName}
                                  clientLastName={this.state.clientLastName}
                                  clientPhone={this.state.clientPhone}
                                  clientEmail={this.state.clientEmail}
                                  isAgreed={this.state.isClientAgreedDirectDebit}
                                  toggleIsAgree={this.toggleIAgreeDirectDebitCheckbox.bind(this)}
                                  authorisedBy={this.state.directDebitAuthorisedBy}
                                  handleAuthorisedByChange={this.handleDirectDebitAuthorisedByChange.bind(this)}
                                  clientCompanyName={this.state.agreement?.client.company_name}
                                  abn={this.state.abn}
                                  handleAbnChange={this.handleAbnChange.bind(this)}/>
                </div>
            </div>}
            {this.state.step === 4 && <div className={"row"}>
                <div className={"col"}>
                    <SummaryPage isAgreedServiceAgreement={this.state.isClientAgreedServiceAgreement}
                                 isAgreedFeeStructure={this.state.isClientAgreedFeeStructure}
                                 isAgreedDirectDebitAgreement={this.state.isClientAgreedDirectDebit}
                                 abn={this.state.abn}
                                 bsb={this.state.clientBsb}
                                 accountNumber={this.state.clientAccountNumber}
                                 accountName={this.state.clientAccountName}
                                 addLine1={this.state.clientAddressLine1}
                                 addLine2={this.state.clientAddressLine2}
                                 city={this.state.clientCity}
                                 state={this.state.clientState}
                                 country={this.state.clientCountry}
                                 postcode={this.state.clientPostCode}
                                 clientFirstName={this.state.clientFirstName}
                                 clientLastName={this.state.clientLastName}
                                 clientPhone={this.state.clientPhone}
                                 clientEmail={this.state.clientEmail}
                                 handleStepChange={this.handleStepChange.bind(this)}
                                 handleSubmit={this.handleSubmit.bind(this)}/>
                </div>
            </div>}
        </div>;
    }
}

const ClientOnboardingAgreement = (_ClientOnboardingAgreement);
export {ClientOnboardingAgreement}
