import React, {Component} from "react";
import {IAgreeCheckbox} from "./i_agree_checkbox";
import {DirectDebitAgreement} from "./direct_debit_agreement";
import Input from 'react-phone-number-input/input'

interface Props{
    handleStepChange(step:number):void
    handleBsbChange(e:any):void
    handleAccountNumberChange(e:any):void
    handleAccountNameChange(e:any):void
    handleClientAddressLine1Change(e:any):void
    handleClientAddressLine2Change(e:any):void
    handleClientCityChange(e:any):void
    handleClientStateChange(e:any):void
    handleClientCountryChange(e:any):void
    handleClientPostcodeChange(e:any):void
    handleFirstNameChange(e:any):void
    handleLastNameChange(e:any):void
    handlePhoneChange(e:any):void
    handleEmailChange(e:any):void
    handleAbnChange(e:any):void
    abn:string
    clientBsb:string
    clientAccountNumber:string
    clientAccountName:string
    clientAddressLine1:string
    clientAddressLine2:string
    clientCity:string
    clientState:string
    clientCountry:string
    clientPostCode:string
    clientFirstName:string
    clientLastName:string
    clientPhone:string
    clientEmail:string
    isAgreed:boolean
    toggleIsAgree(e:any):void

    handleAuthorisedByChange(e:any):void
    authorisedBy:string

    clientCompanyName:string | undefined
}

interface State {
    errorMessage:string
}

class BankInfoPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            errorMessage:""
        }
    }


    toggleAgreeCheckbox(e:any){
        let errorMessage = ""
        if (this.props.abn === ""){
            errorMessage += "ABN is required\n";
        }
        if (this.props.clientBsb === ""){
            errorMessage += "BSB is required\n";
        } else if (this.props.clientBsb.length !== 6){
            errorMessage += "BSB need to be 6 digits\n";
        }
        if (this.props.clientAccountName === ""){
            errorMessage += "Account name is required\n";
        }
        if (this.props.clientAccountNumber === ""){
            errorMessage += "Account number is required\n";
        }
        if (this.props.clientAddressLine1 === ""){
            errorMessage += "Address line 1 is required\n";
        }
        if (this.props.clientCity === ""){
            errorMessage += "Suburb is required\n";
        }
        if (this.props.clientState === ""){
            errorMessage += "State is required\n";
        }
        if (this.props.clientPostCode === ""){
            errorMessage += "Postcode is required\n";
        } else if (this.props.clientPostCode.length !== 4){
            errorMessage += "Postcode must be 4 digits\n";
        }
        if (this.props.authorisedBy === ""){
            errorMessage += "Authorised By is required\n";
        }
        this.setState({errorMessage:errorMessage})
        if (errorMessage === ""){
            this.props.toggleIsAgree(e)
        }
    }

    render() {
        return <div className={"container mt-5"}>
            <div className={"row"}>
                <div className={"col"}>
                    <h2>Direct Debit Authorisation form</h2>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">ABN</label>
                            <input className="form-control" onChange={this.props.handleAbnChange.bind(this)} value={this.props.abn}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h4>Bank Account Information</h4>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Account Number</label>
                            <input className="form-control" onChange={this.props.handleAccountNumberChange.bind(this)} value={this.props.clientAccountNumber}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">BSB</label>
                            <input className="form-control" onChange={this.props.handleBsbChange.bind(this)} value={this.props.clientBsb}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input className="form-control" onChange={this.props.handleAccountNameChange.bind(this)} value={this.props.clientAccountName}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h4>Billing Information</h4>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Address line 1</label>
                            <input className="form-control" onChange={this.props.handleClientAddressLine1Change.bind(this)} value={this.props.clientAddressLine1}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Address line 2</label>
                            <input className="form-control" onChange={this.props.handleClientAddressLine2Change.bind(this)} value={this.props.clientAddressLine2}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">City</label>
                            <input className="form-control" onChange={this.props.handleClientCityChange.bind(this)} value={this.props.clientCity}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">State</label>
                            <input className="form-control" onChange={this.props.handleClientStateChange.bind(this)} value={this.props.clientState}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Country</label>
                            <input className="form-control" disabled value={this.props.clientCountry}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Postcode</label>
                            <input className="form-control" onChange={this.props.handleClientPostcodeChange.bind(this)} value={this.props.clientPostCode}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h4>Contact Information</h4>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">First Name</label>
                            <input className="form-control" onChange={this.props.handleFirstNameChange.bind(this)} value={this.props.clientFirstName}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            <input className="form-control" onChange={this.props.handleLastNameChange.bind(this)} value={this.props.clientLastName}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Phone</label>
                            <div className={"phoneNumberContainer"}>
                                <Input className="form-control phoneInput"
                                       international
                                       country="AU"
                                       value={this.props.clientPhone}
                                       onChange={(value)=>{
                                           console.log("phone number",value);
                                           if (value !== undefined){
                                               this.props.handlePhoneChange(String(value))
                                           }
                                       }}/>
                                <span className="countryCode">+61</span>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input className="form-control" onChange={this.props.handleEmailChange.bind(this)} value={this.props.clientEmail}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <DirectDebitAgreement clientCompanyName={this.props.clientCompanyName}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <div className="mb-3">
                        <label className="form-label">Authorised By: </label>
                        <input className="form-control" placeholder={"Full Name"} value={this.props.authorisedBy} onChange={this.props.handleAuthorisedByChange.bind(this)} required/>
                    </div>
                    {this.state.errorMessage !== "" && <span style={{whiteSpace:"pre-line"}} className={"text-danger"}>{this.state.errorMessage}</span>}
                    <div className="mb-3">
                        <IAgreeCheckbox isChecked={this.props.isAgreed} fileName={"Direct Debit Agreement"} buttonClick={this.toggleAgreeCheckbox.bind(this)}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col d-flex justify-content-between"}>
                    <button type="button" className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleStepChange(2);
                    }}>
                        Previous
                    </button>
                    <button disabled={!this.props.isAgreed} type="button" className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleStepChange(4);
                    }}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    }
}

export {BankInfoPage}

