import axios, {AxiosPromise} from 'axios';


function postAPI(path: string, data: any, operation?: string, contentType?: string): AxiosPromise<any> {
    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": ""
        }
    });

    let op = instance.post;
    if (operation === "DELETE") {
        op = instance.delete;
        return op(path)
    }
    return op(path, JSON.stringify(data));
}

function getAPI(path: string): AxiosPromise<any> {
    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": ""
        }
    });

    return instance.get(path);
}

export {postAPI, getAPI};
