import './App.css';
import {Component} from "react";
import { createBrowserHistory } from "history";
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {ClientOnboardingAgreement} from "./client_onboarding_agreement";
import {SuccessPage} from "./success_page";
import { StartPage } from './start_page';
import './main.scss';

const history = createBrowserHistory();

class App extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return <BrowserRouter history={history}>
            <Switch>
                <Route exact path="/client_onboarding_agreement/:token" component={ClientOnboardingAgreement} />
                <Route exact path="/success" component={SuccessPage} />
                <Route path="/" component={StartPage} />
            </Switch>
        </BrowserRouter>
    }
}

export default App;
