import React, {Component} from "react";
import {IAgreeCheckbox} from "./i_agree_checkbox";

interface Props{
    isAgreed:boolean
    toggleIsAgree(e:any):void
    handleStepChange(step:number):void
}

interface State {
}

class ServiceAgreement extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {
        return <div className={"container mt-5"}>
            <div className={"row"}>
                <div className={"col"}>
                    <h3>Service Agreement</h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <ol>
                        <li>
                            <span>
                                <b>What this Agreement comprises of</b>
                            </span>
                            <p>This agreement between you and us (Vending on Track Pty Ltd, VoT) comprises these terms and conditions, <b>Price Schedule</b> attached to this agreement (together forming the Agreement).</p>
                        </li>
                        <li>
                            <span>
                                <b>The Equipment and Services</b>
                            </span>
                            <ol type="a">
                                <li>
                                    <p>You contract with us to provide you with the Services and the Equipment and we provide you with the Services and Equipment</p>
                                </li>
                                <li>
                                    <p>The Equipment will be used by third parties for cashless payment transactions with debit cards and credit cards (Cards)</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>Term and termination provisions</b>
                            </span>
                            <p>This Agreement:</p>
                            <ol type="a">
                                <li>
                                    <p>
                                        Will commence on the Commencement Date
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Subject to (c) below, will continue until terminated by either party on 30 days’ notice to the other
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Either party may terminate this Agreement during the Term upon 14 days’ notice where a party materially breaches this Agreement, (including non-payment of monies due under this agreement) and fails to remedy that breach within 14 days of receiving notice of the breach
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>Fees and Payment</b>
                            </span>
                            <ol type="a">
                                <li>
                                    <p>
                                        The Customer must pay VoT the fees and charges set out in the <b>Price Schedule</b>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        VoT may update <b>Price Schedule</b> from time to time
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Payments owing must be paid to us without deduction or set off strictly in accordance with our payment terms as shown on our invoice.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        If you owe us money and fail to make payment within seven business days of receipt of a demand for payment, then without prejudice to any other right or remedy available to us, we may at any time:
                                    </p>
                                    <ol type="i">
                                        <li>
                                                Terminate the Agreement or suspend any Services provided under the Agreement (without being liable to you for any losses so caused); and/or
                                        </li>
                                        <li>
                                                Charge you interest on such outstanding amounts calculated at 6% per annum from the date that the amount became due for payment until the date that amount is paid in full.

                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        You may not withhold from, set off against or otherwise reduce any payments due to us unless agreed in writing by us
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>Customer acknowledgements</b>
                            </span>
                            <p>The customer acknowledges:</p>
                            <ol type="a">
                                <li>
                                    <p>
                                        When a third party uses a Card to conduct a cashless payment transaction using the Equipment, the Card will be submitted to a third party card processor for Card validation and transaction authorisation
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        For any transaction during the settlement process that is subsequently declined by the card processor for any other reason, the risk of the associated loss of vended product, settlement funds and transaction fee for that transaction shall be assumed in its entirety by the Customer
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        In the events that vending machines reports “failure to vend”, VoT will cancel the authorised transaction to third party card processor on behalf of you
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The provision of transaction processing Services may depends on factors beyond VoT’s control, including but not limited to factors affecting,
                                    </p>
                                    <ol type="i">
                                        <li>
                                            <p>
                                                Telecommunications systems run by public telecommunications operators or internet service providers
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Banking systems (meaning the systems used by: banking, financial institution or other similar bodies)
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        VoT is not and shall not be obliged to provide the vending machine connectivity services where such factors as set out above prevent it from doing so
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        VoT uses and relies upon external providers in the delivery of its Services using the Equipment, including but not limited to:
                                    </p>
                                    <ol type="i">
                                        <li>
                                                Card processing service providers like latpay
                                        </li>
                                            <li>
                                                Wireless data network providers such as Telstra
                                            </li>
                                            <li>
                                                Cloud service providers like Amazon AWS
                                            </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        The service provided by wireless data network providers such as Telstra,
                                    </p>
                                    <ol type="i">
                                        <li>
                                            <p>
                                                Is subject to transmission limitations and dropped or interrupted transmissions,
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                May be temporarily refused, limited, interrupted, or curtailed due to government regulations or orders, atmospheric and/or topographical conditions and system modifications
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        Certain vending machine brands or models or firmwares or configurations may affect VoT’s ability to provide services.
                                    </p>
                                </li>
                                <li>
                                    <p>Certain features from VoT may depend on certain functionalities provided by vending machines. VoT may not be able to provide full or part of claimed features on certain vending machines if they are not supported by vending machines.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Warranties, repair and replacement
                                </b>
                            </span>
                            <ol type="a">
                                <li>
                                    <p>
                                        We warrant that
                                    </p>
                                    <ol type="i">
                                        <li>
                                            <p>
                                                Equipment provided will work in accordance with manufacturers specification when provided by us or one of our resellers to you;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We will provide the Services in a proper workmanlike and professional manner at all times.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>We do <b>not</b> warrant that the use of the Equipment will be uninterrupted or error-free</p>
                                </li>
                                <li>
                                    <p>Should a piece of Equipment fail you will be responsible for returning it to us for repair or replacement</p>
                                </li>
                                <li>
                                    <p>Your sole remedy (and during the term of this Agreement only) in the event of defective Equipment is replacement by us.</p>
                                </li>
                                <li>
                                    <p>We reserve the right to charge you for the repair or replacement of any Equipment that is returned to us in a damaged state</p>
                                </li>
                                <li>
                                    <p>The Customer shall be responsible for the installation of the Equipment in accordance with our instructions.</p>
                                </li>
                                <li>
                                    <p>
                                        To the maximum extent permitted by law, all express or implied guarantees, warranties,, representations and other terms and conditions of any kind in relation to this Agreement not contained in this Agreement are expressly excluded. If any guarantee, warranty, term or condition is implied or imposed in relation to this Agreement under the Australian Consumer Law or any other applicable legislation and cannot be excluded (a Non-Excludable Provision), then our liability for breach of the Non-Excludable Provision is limited to one or more of the following at our option
                                    </p>
                                    <ol type="i">
                                        <li>
                                            <p>
                                                In the case of the Services, the resupply of the services or the cost of having services supplied again; and
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                In the case of Equipment, the replacement of the Equipment or the supply of equivalent Equipment, the repair of such Equipment.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Exclusions of liability
                                </b>
                            </span>
                            <ol type="a">
                                <li>
                                    <p>
                                        VoT does not cover and in no event and circumstance shall we be liable for and the Customer holds VoT, its affiliates, directors, officiers, employees, agents and assigns harmless for
                                    </p>
                                    <ol type="i">
                                        <li>
                                            <p>Any losses, damages, or business interruptions sustained as a result of interruptions caused by third party providers, including but not limited to its wireless data network providers and processing service providers</p>
                                        </li>
                                        <li>
                                            <p>
                                                Repair of damage to Equipment arising from the act, error, fault, neglect, misuse, improper operation or omission of you or your employee servants, agents, contractors or invitees
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Repair of damage to Equipment arising from changes, alterations, additions or modifications of any Equipment by a person other than us.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Repair of damage to Equipment caused by incorrect power supply, failure of electrical power, air-conditioning, humidity control or any environment factor.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Repair of damage to Equipment caused by the operation of the Equipment other than in accordance with the specifications or the direction, instruction or recommendations of the manufacturer or us
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Any indirect, incidental, special, exemplary, punitive, third party or consequential loss or for any loss of business profits, loss of production, business interruption, loss of data or loss of business information arising out of, based on or resulting from this agreement
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        In no event shall VoT be liable for any punitive, incidental, or consequential damages or any damages for loss of profits, business interruption, loss of information, or pecuniary loss, even if VoT has been advised of the possibility of such damages
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The customer shall indemnify and hold harmless VoT its officers, directors, agents, suppliers, and employees from and against any and all claims, demands, causes of action, obligations, liabilities, expenses (including reasonable legal fees), demaages, or suits whatsoever, in connection with, arising out of, or relating to, in whole or in part, any act or omission of Customer, including, but not limited to, the operation and management of the Equipment,
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        These limitations and exclusions apply regardless of whether the loss, liability or damages arise from breach of contract, negligence or any other cause of action.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Cap on liability
                                </b>
                            </span>
                            <p>
                                Our maximum aggregate liability for all claims under or in connection with thes agreement or its subject matter is limited to an amount equal to the fees paid by your pursuant to this Agreement over the period that the claim is applied to.
                            </p>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Force majeure
                                </b>
                            </span>
                            <p>
                                Except for your oblication ot pay the fees, neither party will be liable to other party for any delay or failure to perform any of its oblications under this Agreement if such delay or failure is due to a cause or matter beyond the reasonable control of that party, including wars, terrorism  (including cyble terrorism), strikes, lockouts, delays or defaults of manfuacturers or suppliers, acts of God including nature events like fire, flood, earthquake or other adverse weather conditions (Force Majeure Event). We may suspend or terminate this Agreement with immediate effect by giving you notice if a Force Majeure Event persists for more than 14 days. If we suspend or terminate this Agreement you are entitled to a refund of any fees paid by you for the period following such suspension or termination
                            </p>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Confidentiality
                                </b>
                            </span>
                            <p>
                                This agreement shall remain confidential as between the parties and may not be disclosed to third parties unless required by law or ethical obligation.
                            </p>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Dispute Resolution
                                </b>
                            </span>
                            <ol type="a">
                                <li>
                                    <span>
                                        <b>
                                            Notice of dispute
                                        </b>
                                    </span>
                                    <p>
                                        If a difference or dispute (Dispute) between the parties arises out of or relates to this Agreement, then the party claiming the dispute may give the other party a written notice of the Dispute identifying and providing details of the Dispute (Notice of Dispute).
                                    </p>
                                </li>
                                <li>
                                    <span>
                                        <b>
                                            Consultation
                                        </b>
                                    </span>
                                    <p>
                                        Within 10 business days after receiving a Notice of Dispute, a senior representative (or the nominee) of each party must meet and attempt to resolve the Dispute within 10 Business days of receipt of the Notice of Dispute. All aspects of every such conference except the fact of its occurrence, will be privileged.
                                    </p>
                                </li>
                                <li>
                                    <span>
                                        <b>
                                            Mediation
                                        </b>
                                    </span>
                                    <p>
                                        If the dispute is not resolved within the following 10 Business Days (or such other period as agreed), the parties will endeavour in good faith to settle the Dispute by mediation administered by the Australian Commercial Disputes Centre before having recourse to arbitration or litigation. The mediation will be conducted in accordance with Australian Commercial Disputes Centre Mediation Guidelines.
                                    </p>
                                </li>
                                <li>
                                    <span>
                                        <b>
                                            Court proceedings
                                        </b>
                                    </span>
                                    <p>
                                        A party must not start court proceedings unless they have first complied with clause 11. However, nothing in this clause prejudices the right of a party to institute proceedings to seek injunctive or urgent declarative relief.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Others
                                </b>
                            </span>
                            <ol type="a">
                                <li>
                                    <p><b>Governing law and jurisdiction:</b>This agreement is governed by the law of New South Wales and the parties consent to the exclusive jurisdiction of the courts of New South Wales and the Commonwealth of Australia</p>
                                </li>
                                <li>
                                    <p><b>Notices:</b>Any notices, consent, or any other communication given under this Agreement is only effective if it is in writing, signed by or on behalf of the party giving it and received at the addressee’s  address or email. Your contact details are as specified in the Information Schedule.</p>
                                </li>
                                <li>
                                    <p><b>Severable Provisions:</b>Any terms of this Agreement which is wholly or partially void or unenforceable is served to the extent that is void or unenforceable. The validity or enforceability of the remainder of this agreement is not affected</p>
                                </li>
                                <li>
                                    <p><b>Waiver:</b>The failure by a party to exercise or delay exercising a right or power under this agreement does not operate as a waiver of that right or power and does not preclude the future exercise of the right or power</p>
                                </li>
                                <li>
                                    <p><b>Assignment and subcontracting:</b>You may not assign , novate, subcontract or otherwise transfer any of your rights or obligations under this Agreement without the prior written consent of the other party (which consent may not unreasonably withheld).</p>
                                </li>
                                <li>
                                    <p><b>Entire Agreement:</b>This agreement constitutes the entire Agreement between the parties in connection with their respective subject matter and supersedes all previous agreements or understandings between the parties in connection with the relevant subject matter.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <IAgreeCheckbox isChecked={this.props.isAgreed} fileName={"Service Agreement"} buttonClick={this.props.toggleIsAgree.bind(this)}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col d-flex justify-content-end"}>
                    <button disabled={!this.props.isAgreed} type="button" className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleStepChange(2);
                    }}>
                        Next
                    </button>
                </div>
            </div>
        </div>;
    }
}

export {ServiceAgreement};
